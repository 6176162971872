import ThemeSwitcher from "../ThemeSwitcher";

function Header() {
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error("Fullscreen Not Working");
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <>
      <div id="db-wrapper">
        <nav className="navbar-vertical navbar">
          <div className="vh-100 overflow-y-scroll pt-1" data-simplebar="">
            <div className="card bg-primary shadow-none text-center mx-3 my-3 border-0">
              <div className="card-body py-5">
                <img src="/assets/images/icon.png" width="80" alt="" />
                <div className="mt-4">
                  <h5 className="text-white">Acharyakulam</h5>
                  <p className="text-white fs-6">
                    SUMAN KUMARI SHARMA
                    <br />
                    06/09/2024
                  </p>
                  <a href="#" className="btn btn-white btn-sm mt-2 px-4 me-1">
                    Logout
                  </a>
                  <a href="#" className="btn btn-white btn-sm mt-2 px-3">
                    <i className="bi bi-gear text-dark"></i>
                  </a>
                </div>
              </div>
            </div>
            <ul className="navbar-nav flex-column pt-2 pb-5" id="sideNavbar">
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navaccount"
                  aria-expanded="false"
                  aria-controls="navaccount"
                >
                  <i className="nav-icon bi bi-buildings me-2" />
                  Front Office
                </a>
                <div
                  id="navaccount"
                  className="collapse show"
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Admission Enquiry
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Visitor Book
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Complain Box
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navsettings"
                  aria-expanded="false"
                  aria-controls="navsettings"
                >
                  <i className="nav-icon bi bi-person me-2" />
                  Student Information
                </a>
                <div
                  id="navsettings"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Admission
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Online Admission
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Disabled Students
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Bulk Delete
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Categories
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student House
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Disable Reason
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navroutine"
                  aria-expanded="false"
                  aria-controls="navroutine"
                >
                  <i className="nav-icon bi bi-wallet me-2" />
                  Fees Collection
                </a>
                <div
                  id="navroutine"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Collect Fees
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Search Fees Payment
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Search Due Fees
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Fees Master
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Fees Group
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Fees Type
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Fees Discount
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Fees Reminder
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navCourses"
                  aria-expanded="false"
                  aria-controls="navCourses"
                >
                  <i className="nav-icon bi bi-people me-2" />
                  Behaviour Report
                </a>
                <div
                  id="navCourses"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Assign Incident
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Incidents
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Reports
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navProfile"
                  aria-expanded="false"
                  aria-controls="navProfile"
                >
                  <i className="nav-icon bi bi-currency-rupee me-2" />
                  Expenses
                </a>
                <div
                  id="navProfile"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Add Expense
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Search Expense
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Expense Head
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navCMS"
                  aria-expanded="false"
                  aria-controls="navCMS"
                >
                  <i className="nav-icon bi bi-book me-2" />
                  Examinations
                </a>
                <div
                  id="navCMS"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Marks Grade
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Marks Division
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navProject"
                  aria-expanded="false"
                  aria-controls="navProject"
                >
                  <i className="nav-icon bi bi-graph-up me-2" />
                  Attendance
                </a>
                <div
                  id="navProject"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Attendance
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Approve Leave
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Attendance By Date
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navAuthentication"
                  aria-expanded="false"
                  aria-controls="navAuthentication"
                >
                  <i className="nav-icon bi bi-pencil-square me-2" />
                  Academics
                </a>
                <div
                  id="navAuthentication"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Assign Class Teacher
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Promote Students
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Subject Group
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Subjects
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Class
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Sections
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navecommerce"
                  aria-expanded="false"
                  aria-controls="navecommerce"
                >
                  <i className="nav-icon bi bi-person-standing me-2" />
                  Human Resource
                </a>
                <div
                  id="navecommerce"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Staff Directory
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Staff Attendance
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Payroll
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Approve Leave Request
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Apply Leave
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Leave Type
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Teachers Rating
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Department
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Designation
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Disabled Staff
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navlayouts"
                  aria-expanded="false"
                  aria-controls="navlayouts"
                >
                  <i className="nav-icon bi bi-chat me-2" />
                  Communicate
                </a>
                <div
                  id="navlayouts"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Notice Board
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link " href="#">
                        Send Email
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link " href="#">
                        Send SMS
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link " href="#">
                        Email / SMS Log
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link " href="#">
                        Email Template
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        SMS Template
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navreports"
                  aria-expanded="false"
                  aria-controls="navreports"
                >
                  <i className="nav-icon bi bi-ui-radios me-2" />
                  Homework
                </a>
                <div
                  id="navreports"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Add Homework
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Daily Assignment
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navcertificate"
                  aria-expanded="false"
                  aria-controls="navcertificate"
                >
                  <i className="nav-icon bi bi-stickies me-2" />
                  Library
                </a>
                <div
                  id="navcertificate"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Book List
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Issue - Return
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbulk"
                  aria-expanded="false"
                  aria-controls="navbulk"
                >
                  <i className="nav-icon bi bi-rocket-takeoff me-2" />
                  Inventory
                </a>
                <div
                  id="navbulk"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Issue Item
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Add Item Stock
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Add Item
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Item Category
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Item Store
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Item Supplier
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navsms"
                  aria-expanded="false"
                  aria-controls="navsms"
                >
                  <i className="nav-icon bi bi-bus-front-fill me-2" />
                  Transport
                </a>
                <div
                  id="navsms"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Fees Master
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Pickup Point
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Routes
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Vehicles
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Assign Vehicle
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Route Pickup Point
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Student Transport Fees
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navsalary"
                  aria-expanded="false"
                  aria-controls="navsalary"
                >
                  <i className="nav-icon bi bi-files me-2" />
                  Certificate
                </a>
                <div
                  id="navsalary"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Certificate
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Generate Certificate
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student ID Card
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Generate ID Card
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Staff ID Card
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Generate Staff ID Card
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  collapsed "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navreport"
                  aria-expanded="false"
                  aria-controls="navreport"
                >
                  <i className="nav-icon bi bi-ui-checks-grid me-2" />
                  Reports
                </a>
                <div
                  id="navreport"
                  className="collapse "
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Student Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Finance
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Attendance
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Human Resource
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Homework
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Library
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Inventory
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Transport
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        User Log
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Audit Trail Report List
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="nav-divider"></div>
              </li>

              <li class="nav-item">
                <div class="navbar-heading">Website</div>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link "
                  href="#"
                  data-bs-toggle="collapse"
                  data-bs-target="#navDashboard"
                  aria-expanded="false"
                  aria-controls="navDashboard"
                >
                  <i className="nav-icon bi bi-images me-2" />
                  Gallery & Achivement
                </a>
                <div
                  id="navDashboard"
                  className="collapse"
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Photos
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Vidoes
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#">
                        Media Print
                      </a>
                    </li>
                    <li className="nav-item pb-2">
                      <a className="nav-link " href="#">
                        Blogs and Articles
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="#">
                  <i class="nav-icon bi bi-wallet me-2"></i>
                  Fee Structure
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="#">
                  <i class="nav-icon bi bi-files me-2"></i>
                  Admission Syllabus
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link " href="#">
                  <i class="nav-icon bi bi-files me-2"></i>
                  Calendar
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <main id="page-content" className="h-auto">
          <div className="header">
            <nav className="navbar-default navbar navbar-expand-lg">
              <div className="d-none d-md-none d-lg-block">
                <form className="d-flex align-items-center">
                  <span className="position-absolute ps-3 search-icon">
                    <i className="bi bi-search" />
                  </span>
                  <input
                    type="search"
                    className="form-control ps-6"
                    placeholder="Search"
                  />
                </form>
              </div>
              <div className="ms-auto d-flex">
                <ul className="navbar-nav navbar-right-wrap ms-2 d-flex nav-top-wrap align-items-center">
                  <li className="me-3">
                    <ThemeSwitcher />
                  </li>
                  <li className="me-2">
                    <button
                      class="btn btn-light btn-icon rounded-circle d-flex align-items-center"
                      type="button"
                      onClick={toggleFullScreen}
                    >
                      <i class="bi bi-arrows-fullscreen"></i>
                    </button>
                  </li>
                  <li className="me-2">
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="bi bi-megaphone me-2" />
                      Notice
                    </a>
                  </li>
                  <li className="me-2">
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="bi bi-gear me-2" />
                      A/C Settings
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      className="btn btn-light btn-icon rounded-circle indicator indicator-primary"
                      href="#"
                    >
                      <i className="bi bi-bell" />
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      className="btn btn-danger btn-icon rounded-circle"
                      href="#"
                    >
                      <i className="bi bi-power" />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </main>
      </div>
    </>
  );
}

export default Header;
