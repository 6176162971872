import Chart from "react-apexcharts";

function Home() {
  const theme = {
    primary: "#754ffe",
    secondary: "#ff5e78",
    success: "#28a745",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    dark: "#343a40",
    light: "#f8f9fa",
    borderColor: "#dee2e6",
    lightPrimary: "#ede9fe",
    darkPrimary: "#593cc1",
  };

  // Chart data and options for "Earning" chart
  const earningChartConfig = {
    series: [
      {
        name: "Current Month",
        data: [10, 20, 15, 25, 18, 28, 22, 32, 24, 34, 26, 38],
      },
    ],
    options: {
      chart: {
        fontFamily: "Inter, sans-serif",
        height: 280,
        type: "line",
        toolbar: { show: false },
      },
      colors: [theme.primary],
      stroke: { width: 4, curve: "smooth", colors: [theme.primary] },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisBorder: { show: false },
        labels: {
          style: {
            fontSize: "13px",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => `${val}k`,
          style: {
            fontSize: "13px",
            fontWeight: 400,
          },
        },
        tickAmount: 3,
        min: 10,
        max: 40,
      },
      grid: {
        borderColor: theme.borderColor,
        strokeDashArray: 5,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -10,
        fontSize: "16px",
      },
      tooltip: { theme: "light" },
    },
  };

  const chartOptions = {
    chart: {
      type: "bar",
      height: 302,
      sparkline: { enabled: true },
    },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "darken", value: 0.55 } },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: { type: "darken", value: 0.55 },
      },
    },
    colors: [theme.secondary], // Fallback color
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "50%",
      },
    },
    series: [
      {
        data: [
          25, 66, 41, 70, 63, 25, 44, 22, 36, 19, 54, 44, 32, 36, 29, 54, 25,
          66, 41, 65, 63, 25, 44, 12, 36, 39, 25, 44, 42, 36, 54,
        ],
      },
    ],
    xaxis: {
      crosshairs: { width: 1 },
    },
    tooltip: {
      fixed: { enabled: false },
      x: { show: false },
      y: {
        title: {
          formatter: () => "Active User",
        },
      },
      marker: { show: false },
    },
  };

  const trafficDountChart = {
    series: [60, 55, 12, 17],
    options: {
      labels: ["Organic Search", "Direct", "Referrals", "Social Media"],
      colors: [theme.primary, theme.success, theme.danger, theme.warning],
      chart: {
        type: "donut",
        height: 260,
      },
      legend: {
        show: false, // Hides legend
      },
      dataLabels: {
        enabled: false, // Disables data labels
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Size of the donut hole
          },
        },
      },
      stroke: {
        width: 2,
        colors: [theme.transparent || "transparent"], // Transparent stroke
      },
    },
  };

  const sessionChart = {
    series: [
      {
        name: "Session Duration",
        data: [600, 1000, 400, 2000, 500, 900, 2500, 1800, 3800],
      },
      {
        name: "Page Views",
        data: [1000, 2000, 800, 1200, 300, 1900, 1600, 2000, 1000],
      },
      {
        name: "Total Visits",
        data: [2200, 1000, 3400, 900, 500, 2500, 3000, 1000, 2500],
      },
    ],
    options: {
      chart: {
        toolbar: { show: false },
        height: 400,
        type: "line",
        zoom: { enabled: false },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 3, 3],
        curve: "smooth",
        dashArray: [0, 5, 4],
      },
      legend: {
        show: false,
      },
      colors: [theme.primary, theme.success, theme.warning],
      markers: {
        size: 0,
        hover: { sizeOffset: 6 },
      },
      xaxis: {
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan",
          "08 Jan",
          "09 Jan",
          "10 Jan",
          "11 Jan",
          "12 Jan",
        ],
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            cssClass: "apexcharts-xaxis-label",
            colors: theme.gray500 || "#6B7280",
          },
        },
        axisBorder: {
          show: true,
          color: theme.borderColor || "#E5E7EB",
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: theme.borderColor || "#E5E7EB",
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            cssClass: "apexcharts-yaxis-label",
          },
          offsetX: -12,
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: theme.borderColor || "#E5E7EB",
      },
    },
  };

  return (
    <>
      <div id="db-wrapper">
        <main id="page-content">
          <section className="container-fluid p-4">
            <div className="row g-2 mb-4">
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card">
                  <div className="card-body d-flex flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semibold ls-md">
                          Students
                        </span>
                      </div>
                      <div>
                        <span className="bi bi-shopping-bag fs-3 text-primary" />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <h2 className="fw-bold mb-0">2,568</h2>
                      <div className="d-flex flex-row gap-2">
                        <span className="text-success fw-semibold">
                          <i className="bi bi-trending-up me-1" />
                          +20
                        </span>
                        <span className="fw-medium">Students</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card">
                  <div className="card-body d-flex flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semibold ls-md">
                          Teachers
                        </span>
                      </div>
                      <div>
                        <span className="bi bi-book-open fs-3 text-primary" />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <h2 className="fw-bold mb-0">245</h2>
                      <div className="d-flex flex-row gap-2">
                        <span className="text-success fw-semibold">+5</span>
                        <span className="fw-medium">Teachers</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card">
                  <div className="card-body d-flex flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semibold ls-md">
                          School Staff
                        </span>
                      </div>
                      <div>
                        <span className="bi bi-users fs-3 text-primary" />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <h2 className="fw-bold mb-0">39</h2>
                      <div className="d-flex flex-row gap-2">
                        <span className="text-success fw-semibold">
                          <i className="bi bi-trending-up me-1" />
                          +12
                        </span>
                        <span className="fw-medium">Staffs</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card">
                  <div className="card-body d-flex flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between lh-1">
                      <div>
                        <span className="fs-6 text-uppercase fw-semibold ls-md">
                          Monthly Fee Collection
                        </span>
                      </div>
                      <div>
                        <span className="bi bi-user-check fs-3 text-primary" />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <h2 className="fw-bold mb-0">2,27,860</h2>
                      <div className="d-flex flex-row gap-1">
                        <span className="text-success fw-semibold">
                          <i className="bi bi-trending-up me-1" />
                          +58440
                        </span>
                        <span className="ms-1 fw-medium">Fee</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="fw-semibold text-uppercase fs-6 ls-md">
                          SMS Notifications
                        </span>
                      </div>
                      <div className="col-6">
                        <h1 className="fw-bold mt-2 mb-0 h2">18</h1>
                        <div className="d-flex flex-row gap-1">
                          <span className="text-danger fw-semibold">
                            <i className="bi bi-trending-up me-1" />
                            -4
                          </span>
                          <span className="ms-1 fw-medium">SMS</span>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-center">
                        <div id="visitorChart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="fw-semibold text-uppercase fs-6 ls-md">
                          Buses
                        </span>
                      </div>
                      <div className="col-6">
                        <h1 className="fw-bold mt-2 mb-0 h2">34</h1>
                        <div className="d-flex flex-row gap-1">
                          <span className="text-success fw-semibold">
                            <i className="bi bi-trending-up me-1" />
                            +3
                          </span>
                          <span className="ms-1 fw-medium">Buses</span>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-center">
                        <div id="bounceChart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-2 mb-2">
              <div class="col-xl-8 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0">Earnings</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <Chart
                      options={earningChartConfig.options}
                      series={earningChartConfig.series}
                      type="line"
                      height={330}
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0">Traffic</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4">
                        <div class="d-flex flex-column gap-2">
                          <span class="fw-semibold">30 days</span>
                          <div>
                            <h1 class="fw-bold mb-0 h2">12,698</h1>
                            <p class="text-success fw-semibold mb-0">
                              <i class="fe fe-trending-up me-1"></i>
                              4.6%
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex flex-column gap-2">
                          <span class="fw-semibold">7 days</span>
                          <div>
                            <h1 class="fw-bold mb-0 h2">2.65K</h1>
                            <p class="text-danger fw-semibold mb-0">
                              <i class="fe fe-trending-down me-1"></i>
                              4.6%
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex flex-column gap-2">
                          <span class="fw-semibold">1 days</span>
                          <div>
                            <h1 class="fw-bold mb-0 h2">1.34K</h1>
                            <p class="text-success fw-semibold mb-0">
                              <i class="fe fe-trending-up me-1"></i>
                              4.6%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Chart
                      options={chartOptions}
                      series={chartOptions.series}
                      type="bar"
                    />

                    <div class="fs-6 d-flex justify-content-between mt-2 ps-2">
                      <div class="chart-label">01 Dec, 2024</div>
                      <div class="chart-label">30 Dec, 2024</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row g-2 mb-4">
              <div class="col-xl-4 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0">Traffic</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <Chart
                      options={trafficDountChart.options}
                      series={trafficDountChart.series}
                      type="donut"
                      height={260}
                    />

                    <table class="table table-sm table-borderless mb-0 mt-5">
                      <tbody>
                        <tr className="d-flex justify-content-center">
                          <td class="d-flex flex-row gap-2">
                            <span class="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                class="bi bi-square-fill text-primary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                              </svg>
                            </span>
                            <span>Organic Search</span>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-center">
                          <td class="d-flex flex-row gap-2">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                class="bi bi-square-fill text-success"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                              </svg>
                            </span>
                            <span>Direct</span>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-center">
                          <td class="d-flex flex-row gap-2">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                class="bi bi-square-fill text-danger"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                              </svg>
                            </span>
                            <span>Refferrals</span>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-center">
                          <td class="d-flex flex-row gap-2">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                class="bi bi-square-fill text-info"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                              </svg>
                            </span>
                            <span>Social Media</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
                    <div>
                      <h4 class="mb-0">Session</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <Chart
                      options={sessionChart.options}
                      series={sessionChart.series}
                      type="line"
                      height={400}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row g-2 mb-2">
              <div class="col-xl-8 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header">
                    <h3 class="mb-0">Account Settings</h3>
                    <p class="mb-0">Few settings to manage your account.</p>
                  </div>
                  <div class="card-body">
                    <div class="row d-lg-flex justify-content-between align-items-center">
                      <div class="col-lg-8 col-md-7 col-12 mb-3 mb-lg-0">
                        <h4 class="mb-0">Maintenence Mode</h4>
                        <p class="mb-0">Show ERP to all / Hide from all</p>
                      </div>
                      <div class="col-lg-4 col-md-5 col-12">
                        <label
                          class="form-label visually-hidden"
                          for="selectState"
                        >
                          State
                        </label>
                        <select
                          class="form-select"
                          data-choices=""
                          id="selectState"
                          name="selectState"
                          required
                        >
                          <option value="">Select</option>
                          <option value="public">Public</option>
                          <option value="maintenence">Maintenence</option>
                        </select>
                      </div>
                    </div>
                    <hr class="my-5" />
                    <div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Send SMS</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="swicthOne"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="swicthOne"
                              ></label>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Send Email Notifications</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="switchTwo"
                              />
                              <label
                                class="form-check-label"
                                for="switchTwo"
                              ></label>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Apply Leave</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="switchThree"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="switchThree"
                              ></label>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Complain Box</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="switchFour"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="switchFour"
                              ></label>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Add Expenses</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="switchFive"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="switchFive"
                              ></label>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Generate Staff ID Card</div>
                          <div>
                            <div class="form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="switchFive"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="switchFive"
                              ></label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-12 col-md-12 col-12">
                <div class="card h-100">
                  <div class="card-header">
                    <h3 class="mb-0">Security Password</h3>
                    <p class="mb-0">Change password of your account.</p>
                  </div>
                  <div class="card-body">
                    <div>
                      <form class="row needs-validation" novalidate>
                        <div class="col-12">
                          <div class="mb-3">
                            <label class="form-label" for="securityCurrentPass">
                              Current password
                            </label>
                            <input
                              id="securityCurrentPass"
                              type="password"
                              name="securityCurrentPass"
                              class="form-control"
                              placeholder=""
                              required
                            />
                            <div class="invalid-feedback">
                              Please enter current password.
                            </div>
                          </div>
                          <div class="mb-3 password-field">
                            <label class="form-label" for="securityNewPass">
                              New password
                            </label>
                            <input
                              id="securityNewPass"
                              type="password"
                              name="securityNewPass"
                              class="form-control mb-2"
                              placeholder=""
                              required
                            />
                            <div class="invalid-feedback">
                              Please enter new password.
                            </div>
                            <div class="row align-items-center g-0">
                              <div class="col-6">
                                <span
                                  data-bs-toggle="tooltip"
                                  data-placement="right"
                                  title="Test it by typing a password in the field below. To reach full strength, use at least 6 characters, a capital letter and a digit, e.g. 'Test01'"
                                >
                                  Password strength
                                  <i class="fe fe-help-circle ms-1"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label" for="securityConfirmPass">
                              Confirm New Password
                            </label>
                            <input
                              id="securityConfirmPass"
                              type="password"
                              name="securityConfirmPass"
                              class="form-control mb-2"
                              placeholder=""
                              required
                            />
                            <div class="invalid-feedback">
                              Please enter confirm new password.
                            </div>
                          </div>
                          <button type="submit" class="btn btn-primary px-5">
                            Save Password
                          </button>
                          <div class="col-6"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Home;
