import React, { useEffect, useState } from "react";

const ThemeSwitcher = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return (
      savedTheme ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
    );
  });

  useEffect(() => {
    const applyTheme = (theme) => {
      document.documentElement.setAttribute("data-bs-theme", theme);
    };

    const updateUI = (theme) => {
      document.querySelectorAll("[data-bs-theme-value]").forEach((button) => {
        button.classList.remove("active");
        button.setAttribute("aria-pressed", "false");
      });

      const activeButton = document.querySelector(
        `[data-bs-theme-value="${theme}"]`
      );
      if (activeButton) {
        activeButton.classList.add("active");
        activeButton.setAttribute("aria-pressed", "true");
      }
    };

    applyTheme(theme);
    updateUI(theme);

    const handleMediaChange = () => {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (!localStorage.getItem("theme")) {
        setTheme(prefersDark ? "dark" : "light");
      }
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [theme]);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <button
        className="btn btn-light btn-icon rounded-circle d-flex align-items-center me-1"
        type="button"
        data-bs-theme-value="dark"
        aria-pressed={theme === "dark"}
        onClick={() => handleThemeChange("dark")}
      >
        <i className="bi bi-moon"></i>
      </button>
      <button
        className="btn btn-light btn-icon rounded-circle d-flex align-items-center"
        type="button"
        data-bs-theme-value="light"
        aria-pressed={theme === "light"}
        onClick={() => handleThemeChange("light")}
      >
        <i className="bi bi-sun"></i>
      </button>
    </div>
  );
};

export default ThemeSwitcher;
